import React, {useEffect, useState} from "react";
import {Box, Button, Card, CircularProgress, IconButton, MenuItem, Stack, TextField, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import {firebase} from "../firebase/clientApp";
import {Edit} from "@mui/icons-material";
import axios from "axios";
import {API_URL} from "../config";
import {CustomAlert} from "./shared/CustomAlert";

export const DisplayName = ({teams, userData, setUserData}) => {
    const {id} = useParams()
    const [state, setState] = useState({displayName: '', teamName: ''})
    const [error, setError] = useState(false)
    const [setting, setSetting] = useState(false)
    const [editing, setEditing] = useState(false)

    useEffect(() => {
        if (userData) {
            setState(userData)
        }
    }, [userData])

    const handleSetDisplayName = (event) => {
        setState({...state, teamName: event.target.value})
    }

    const handleSetTeam = (event) => {
        setState({...state, teamName: event.target.value})
    }

    const submitDisplayName = async (event) => {
        event.preventDefault()
        setSetting(true)
        let uid = firebase.auth().currentUser.uid
        await axios.post(`${API_URL}/events/${id}/users`, {user: uid, displayName: state.displayName, teamName: state?.teamName ?? ""})
            .then(res => {
                    setUserData({displayName: state.displayName ?? '', teamName: state.teamName ?? ''})
                    setError("")
                    setEditing(false)
            })
            .catch((err) => {
                console.log("NickName già preso!!!", err?.response?.data?.message ?? "There was an error")
                setError(err?.response?.data?.message ?? "There was an error")
            })
            .finally(() => {
                setSetting(false)
            })
    }

    return (
        <Box>
            {
                (editing || userData.teamName ==='' || !userData.teamName) ?
                    <Box component={Card} p={2} sX={{background: 'red'}}>
                        <Typography textAlign={'center'} fontWeight={'bold'} fontSize={20} sx={{pb: 2}}>
                            Dove sei seduto?
                        </Typography>
                        <form onSubmit={submitDisplayName}>
                            <TextField
                                select
                                fullWidth
                                required
                                inputProps={{style: {fontSize: '1.2rem'}}}
                                id="teamName"
                                value={state.teamName}
                                onChange={handleSetTeam}
                            >
                                {teams?.map((option) => (
                                    <MenuItem key={option} value={option.id}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <Box pt={2} display={'flex'} justifyContent={'center'}>
                                {editing && <Button sx={{color: 'white'}} onClick={() => setEditing(false)}>
                                    Annulla
                                </Button>}
                                {setting ? <CircularProgress/> :
                                    <Button variant={'contained'} type={'submit'}
                                            disabled={
                                                (editing && userData.teamName === state.teamName) || (!state.teamName)
                                            }>
                                        invia
                                    </Button>}
                                <CustomAlert open={!!error} message={error} setOpen={setError} severity={'error'}/>
                            </Box>
                        </form>
                    </Box>
                    :
                    <Stack my={2} direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={1}>
                        <Stack>
                            {/*<Typography gutterBottom textAlign={'center'} fontSize={18}>
                                User: {userData.displayName}
                            </Typography>*/}
                            {userData?.teamName ? <Typography textAlign={'center'} fontSize={20}>
                                {userData.teamName ? `TEAM: ${userData.teamName}` : ''}
                            </Typography> : null}
                        </Stack>
                        <IconButton size={'small'} color={'primary'} onClick={() => setEditing(!editing)}>
                            <Edit/>
                        </IconButton>
                    </Stack>
            }

        </Box>
    )
}
