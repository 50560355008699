import React from "react";
import {Box} from "@mui/material";

import axios from "axios";
import {useParams} from "react-router-dom";

import clap from '../assets/images/clap.png'
import {emojisplosion} from "emojisplosion";
import {API_URL} from "../config";


export default function Applausometro({url, idPlaying, eventPlaying, teams}) {
    const {id} = useParams()

    async function postDataApplausi(e, team) {
        emojisplosion({
            emojiCount:5,
            emojis:['👏'],
            position: () => ({
                x: e.clientX,
                y: e.clientY,
            }),
        })
        await axios.post(`${API_URL}/${id}/reactions?type=${team}`)
            .then(r => {
                navigator.vibrate(200)
            })
            .catch(error => console.log(error))
    }

    const ButtonItem = ({team}) => (
        <Box id='clapButton' disabled={!eventPlaying} onClick={(e) => postDataApplausi(e, team.id)}
             justifyContent={'center'} alignItems={'center'}
             p={'12px'}
             borderRadius={'8px'}
             sx={{
                 bgcolor: team.color || '#3482a3',
                 cursor: 'pointer',
             }}>
            <img style={{margin: 'auto', height:'5.625rem'}} src={clap} />
        </Box>
    )

    return (
        <Box display={'flex'} justifyContent={'space-evenly'}
             sx={{position:'absolute', bottom: 10, my:2, width:'min(35rem, calc(100% - 32px))'}}>
            {teams.map((team) => (<ButtonItem team={team}/>))}
        </Box>
    )
}
