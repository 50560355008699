import React from "react";
import {List, ListItem, styled, Typography} from "@mui/material";
import {THEME_CONFIG} from "../../../config";
import pleshDark from "../../../theme/pleshDark";

const {poll} = THEME_CONFIG

export default function QuizQuestion({risposte, corretta, hideResult = false, answer, sendAnswer, isPoll}) {
    const RispostaStyled = styled(ListItem)({
        border: `2px solid ${poll.default}`,
        borderRadius: '4px',
        background: poll.default,
        margin: '1.5rem 0',
        cursor: 'pointer',
        paddingInline: '10%'
    })

    const computeBkg = (r) => {
        if(!isPoll && hideResult === false) {
            if (answer !== '') {
                if (corretta === r) {
                    return poll.correct
                } else if (answer === r  && answer !== corretta){
                    return poll.wrong
                }
            }
        } else {
            if(answer === r) {
                return poll.answered
            }
        }

    }

    const Risposte = () => (
        risposte.map((r, i) => (
            <RispostaStyled key={i} style={{
                width: '100%',
                background: computeBkg(r),
                border: computeBkg(r) ? '2px solid '+ computeBkg(r) : `2px solid ${poll.default}`,
                color: answer === r ? pleshDark.palette.primary.main : 'white',
            }} onClick={() => rispondi(r)}>
                <Typography variant={"h5"} fontWeight={'400'} sx={{textAlign:'center', width:'100%'}}>
                    {r}
                </Typography>
            </RispostaStyled>
        ))
    )

    const rispondi = (r) => {
        if(isPoll) {
            sendAnswer(r)
        } else {
            if(answer === ''){
                sendAnswer(r)
            }
        }
    }

    return (
        <List sx={{paddingX: 3}}>
            <Risposte/>
        </List>
    )
}
