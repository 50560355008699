export const API_URL = "https://api.secondstage.app"
//export const API_URL = "http://localhost:8080"
//export const TOOLPRESS_API = "https://api-tool-press-yn26wrnl4q-ey.a.run.app"
export const TOOLPRESS_API = "https://toolpress-api.2ndstage.app"

export const THEME_CONFIG = {
    mode:"dark",
    gradients: {
        default: ['#e40521', '#e40521'],
        home_question: ['#e40521', '#e40521'],
        applausometro: ['#e40521', '#e40521'],
        siNo:['#e40521', '#e40521'],
        sentiment: ['#e40521', '#e40521'],
        wordcloud: ['#e40521', '#e40521'],
        qna: ['#e40521', '#e40521'],
    },
    primary: '#e40521',
    secondary: '#263238',
    accent: '#e40521',
    text: '#ffffff',
    background:"#01193a",
    poll:{
        default:"#e40521",
        answered:"#ffffff",
        correct: '#3A722D',
        wrong: '#DB0A16'
    }
}